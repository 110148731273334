import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  Card,
  Upload,
  Input,
  Button,
  Form,
  Tag,
  Modal,
  Dropdown,
  Menu,
  Space,
  message,
  DatePicker,
  InputNumber,
  Checkbox,
  Divider,
} from "antd";
import {
  DownloadOutlined,
  SyncOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  SearchOutlined,
  FilterFilled,
} from "@ant-design/icons";
import Papa from "papaparse";
import moment from "moment-timezone";
import { db, storage } from "utils/firebase";
import useAuth from "hooks/useAuth";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import * as uuid from "uuid";

import "./styles.less";
import axios from "axios";
import { MeiliSearch } from "meilisearch";
import { IS_FUNIX } from "utils/constants";

import useDataToolRole from "hooks/useDataToolRole";
import AssignForm from "./AssignForm";

import { saveAs } from "file-saver";
import JSZip from "jszip";
import { getUrlExtension } from "utils/utils";
import { chunk } from "lodash";
import csvDownload from "json-to-csv-export";

const { confirm } = Modal;

const { Dragger } = Upload;

const {
  REACT_APP_TENANT_ID,
  REACT_APP_FUNIX_API,
  REACT_APP_MEILI_HOST,
  REACT_APP_MEILI_API_KEY,
} = process.env;

const searchClient = IS_FUNIX
  ? new MeiliSearch({
      host: REACT_APP_MEILI_HOST,
      apiKey: REACT_APP_MEILI_API_KEY,
    })
  : null;

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const DataToolTag = ({ file, role }) => {
  if (
    (role === "annotator" && file.annotatorSubmitted) ||
    (role === "reviewer" && file.reviewerSubmitted) ||
    (role === "approver" && file.approverSubmitted)
  ) {
    return <Tag color="#87d068">Done</Tag>;
  }

  if (
    (role === "annotator" && file.annotatorEdited) ||
    (role === "reviewer" && file.reviewerEdited) ||
    (role === "approver" && file.approverEdited)
  ) {
    return <Tag color="#108ee9">Doing</Tag>;
  }

  if (
    (role === "annotator" && file.annotator) ||
    (role === "reviewer" && file.reviewer) ||
    (role === "approver" && file.approver)
  ) {
    return <Tag color="#ff9300">Assigned</Tag>;
  }

  return (
    <Tag style={{ color: "black" }} color="#DDE6ED">
      New
    </Tag>
  );
};

const SaasTranscripts = ({ baseUrl = "/user/transcripts" }) => {
  const { t } = useTranslation();
  const language = getLanguages(t);

  const { user, token } = useAuth();

  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRows, setSelectedRows] = useState([]);

  const [submit, setSubmit] = useState(false);
  const [lang, setTranscriptLanguague] = useState(undefined);

  const [numberOfSpeakers, setNumberOfSpeakers] = useState();
  const [splitScd, setSplitScd] = useState(false);

  const dataToolRole = useDataToolRole();

  const [isExporting, setIsExporting] = useState(false);
  const [exportType, setExportType] = useState("csv");

  const [isBatchAssigning, setIsBatchAssigning] = useState(false);

  const fetched = useRef(false);

  useEffect(() => {
    if (user && !fetched.current) {
      fetched.current = true;
      setLoading(true);
      let query = db.collection("files");
      if (!IS_FUNIX) {
        if (dataToolRole === "annotator") {
          query = query.where("annotator", "==", user?.email);
        } else if (dataToolRole === "reviewer") {
          query = query.where("reviewer", "==", user?.email);
        } else if (dataToolRole === "approver") {
          query = query.where("approver", "==", user?.email);
        } else query = query.where("user", "==", user?.uid);
      } else query = query.where("tenantId", "==", REACT_APP_TENANT_ID);

      query.orderBy("createdAt", "desc").onSnapshot((snapshot) => {
        setLoading(true);
        const files = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data?.createdAt?.toDate(),
            length: convertToDuration(data?.length),
            lastEdit: data?.lastEdit?.toDate() || data?.createdAt?.toDate(),
          };
        });

        setFiles(files);
        setFilteredFiles(files);
        setLoading(false);
      });

      db.doc(`users/${user.uid}`)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return;
          }
        })
        .catch((err) => {});
    }
  }, [user]);

  const convertToDuration = (value) => {
    if (isNaN(value) || !isFinite(value)) return "00:00:00";

    // calculate hours
    const hours = Math.floor(value / 3600);
    value -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(value / 60) % 60;
    value -= minutes * 60;

    // calculate minutes
    const seconds = Math.round(value);

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const getColumnSearchProps = (dataIndex, date = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space direction="vertical">
          {date ? (
            <DatePicker.RangePicker
              value={selectedKeys[0]}
              onChange={(e) => {
                if (!e) {
                  setSelectedKeys([]);
                  return;
                }

                setSelectedKeys([e]);
              }}
              allowClear
            />
          ) : (
            <Input
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
            />
          )}
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {language.search}
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: (filtered) =>
      date ? (
        <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
      ) : (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    onFilter: (value, record) => {
      if (date) {
        if (!value || value.length === 0) return true;
        const startDate = value[0].clone().startOf("day").toDate();
        const endDate = value[1].clone().endOf("day").toDate();
        return record[dataIndex] >= startDate && record[dataIndex] <= endDate;
      }

      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  });

  const columns = [
    {
      title: language.name,
      dataIndex: "name",
      // width: "15%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      render: (text, record, index) => {
        if (record.status === "TRANSCRIBED") {
          return (
            <a
              rel="noopener noreferrer"
              target={"_blank"}
              href={baseUrl + "/" + record.id}
            >
              {record.name}
            </a>
          );
        } else {
          return <div>{record.name}</div>;
        }
      },
      ...getColumnSearchProps("name"),
    },
    {
      title: language.length,
      align: "center",
      dataIndex: "length",
      width: "120px",
      sorter: {
        compare: (a, b) => a.length.localeCompare(b.length),
      },
      render: (text, record, index) => {
        if (record.status === "TRANSCRIBED") return <div>{record.length}</div>;
      },
    },
    {
      title: language.uploadDate,
      align: "center",
      dataIndex: "createdAt",
      width: "160px",
      sorter: {
        compare: (a, b) => a.createdAt - b.createdAt,
      },
      render: (text, record, index) => (
        <div>{moment(record.createdAt).format("LL")}</div>
      ),
      ...getColumnSearchProps("createdAt", true),
    },
    // {
    //   title: language.lastEdit,
    //   align: "center",
    //   width: "160px",
    //   sorter: {
    //     compare: (a, b) => a.lastEdit - b.lastEdit,
    //   },
    //   render: (record) => (
    //     <div>{`${moment(record.lastEdit).format("LL")}`}</div>
    //   ),
    // },
    ...(dataToolRole === "reviewer" || dataToolRole === "approver"
      ? [
          {
            title:
              (dataToolRole === "reviewer" ? "Annotator" : "Reviewer") +
              " " +
              language.status,
            width: "5%",
            align: "center",
            render: (record) => (
              <DataToolTag
                file={record}
                role={dataToolRole === "reviewer" ? "annotator" : "reviewer"}
              />
            ),
          },
        ]
      : dataToolRole === "annotator" ||
        dataToolRole === "reviewer" ||
        dataToolRole === "approver"
      ? [
          {
            title: "Deadline",
            width: "5%",
            render: (record) => {
              const deadlineTime =
                dataToolRole === "annotator"
                  ? record.annotatorDeadline
                  : dataToolRole === "reviewer"
                  ? record.reviewerDeadline
                  : record.approverDeadline;
              return deadlineTime
                ? moment(deadlineTime).format("DD/MM/YYYY HH:mm")
                : "None";
            },
          },
        ]
      : []),
    {
      title: language.status,
      align: "center",
      width: "3%",
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
      render: (record) => {
        if (
          dataToolRole === "annotator" ||
          dataToolRole === "reviewer" ||
          dataToolRole === "approver"
        )
          return <DataToolTag file={record} role={dataToolRole} />;
        switch (record.status) {
          case "TRANSCRIBING":
            return (
              <Tag icon={<SyncOutlined spin />} color="#108ee9">
                {language.transcribing}
              </Tag>
            );
          case "TRANSCRIBED":
            return (
              <a target={"_blank"} href={baseUrl + "/" + record.id}>
                <Tag color="#87d068">{language.ready}</Tag>
              </a>
            );
          case "ERROR":
            return <Tag color="#ff0000">{language.error}</Tag>;
          case "UPLOADED":
            return <Tag color="#ff9300">{language.uploaded}</Tag>;
          case "DOWNLOADING":
            return <Tag color="#ff9300">Downloading</Tag>;
          default:
            return null;
        }
      },
    },
    ...(dataToolRole === "admin"
      ? [
          {
            title: "Annotator",
            width: "7%",
            render: (record) => (
              <div>
                <div style={{ marginBottom: 8 }}>
                  {record.annotator ? record.annotator : "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <DataToolTag file={record} role="annotator" />
                </div>
                <div>
                  {record.annotatorDeadline
                    ? moment(record.annotatorDeadline).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : "—"}
                </div>
              </div>
            ),
          },
          {
            title: "Reviewer",
            width: "7%",
            render: (record) => (
              <div>
                <div style={{ marginBottom: 8 }}>
                  {record.reviewer ? record.reviewer : "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <DataToolTag file={record} role="reviewer" />
                </div>
                <div>
                  {record.reviewerDeadline
                    ? moment(record.reviewerDeadline).format("DD/MM/YYYY HH:mm")
                    : "—"}
                </div>
              </div>
            ),
          },
          {
            title: "Approver",
            width: "7%",
            render: (record) => (
              <div>
                <div style={{ marginBottom: 8 }}>
                  {record.approver ? record.approver : "—"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <DataToolTag file={record} role="approver" />
                </div>
                <div>
                  {record.approverDeadline
                    ? moment(record.approverDeadline).format("DD/MM/YYYY HH:mm")
                    : "—"}
                </div>
              </div>
            ),
          },
        ]
      : []),
    {
      title: language.action,
      align: "center",
      width: "2%",
      render: (record) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {dataToolRole === "admin" && (
              <Dropdown
                overlay={<AssignForm file={record} />}
                trigger={["click"]}
              >
                <Button type="link">Assigns</Button>
              </Dropdown>
            )}
            {record.status === "TRANSCRIBED" && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={baseUrl + "/" + record.id}
              >
                <Button type="link" icon={<EyeOutlined />}>
                  {language.btnView}
                </Button>
              </a>
            )}

            {dataToolRole !== "annotator" && dataToolRole !== "reviewer" && (
              <Button
                icon={<DeleteOutlined />}
                type="link"
                danger
                onClickCapture={() => {
                  confirm({
                    title: language.doYouWantToDeleteTheseItems,
                    icon: <ExclamationCircleOutlined />,
                    onOk: async () => {
                      if (user) {
                        setLoading(true);
                        try {
                          if (IS_FUNIX && record.source === "funix") {
                            axios
                              .delete(REACT_APP_FUNIX_API + "/file", {
                                data: {
                                  fileName: record.uuid,
                                },
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              })
                              .then((res) => {
                                if (res.status === 200) {
                                  message.success(language.deleteSuccess);
                                  setLoading(false);
                                } else {
                                  message.error(language.deleteFail);
                                  setLoading(false);
                                }
                              })
                              .catch((err) => {
                                message.error(language.deleteFail);
                                setLoading(false);
                              });
                          } else {
                            if (IS_FUNIX) {
                              searchClient
                                .index("transcripts")
                                .deleteDocument(
                                  record.name.split(".").slice(0, -1).join(".")
                                );
                            }
                            const batch = db.batch();
                            if (record.url) {
                              try {
                                await storage.refFromURL(record.url).delete();
                              } catch {}
                            }
                            if (record.taskId) {
                              batch.update(
                                db.collection("audioTasks").doc(record.taskId),
                                {
                                  status: "DELETED",
                                }
                              );
                            }
                            const fileDoc = db
                              .collection("files")
                              .doc(record.id);
                            const sentences = await fileDoc
                              .collection("sentences")
                              .get();
                            sentences.forEach((doc) => {
                              batch.delete(doc.ref);
                            });

                            const sentencesEdited = await fileDoc
                              .collection("sentencesEdited")
                              .get();
                            sentencesEdited.forEach((doc) => {
                              batch.delete(doc.ref);
                            });

                            const editedDocs = await fileDoc
                              .collection("edited")
                              .get();
                            await Promise.all(
                              editedDocs.docs.map(async (doc) => {
                                const batch = db.batch();
                                const versions = await doc.ref
                                  .collection("versions")
                                  .get();
                                versions.forEach((version) => {
                                  batch.delete(version.ref);
                                });
                                batch.delete(doc.ref);
                                await batch.commit();
                              })
                            );
                            batch.delete(fileDoc);
                            await batch.commit();
                            message.success(language.deleteSuccess);
                          }
                        } catch (e) {
                          console.log(e);
                          message.error(language.deleteFail);
                        }
                        setLoading(false);
                      } else {
                        message.error(language.deleteFail);
                        setLoading(false);
                      }
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                }}
              >
                {language.btnDelete}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const onSelectFile = async (e) => {
    const file = e.file;
    if (file) {
      const fileUUID = uuid.v4();
      const createdAt = moment();

      const fileExtension = file.name.split(".").pop();
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`${fileUUID}.${fileExtension}`);

      const uploadTask = fileRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          if (e.onProgress) e.onProgress({ percent: percent });
          else setSubmit(true);
        },
        (err) => {
          if (e.onError) e.onError(err);
        },
        () => {
          // download url
          uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
            let payload = {
              uuid: fileUUID,
              name: file.name.split(".").slice(0, -1).join("."),
              user: user?.uid,
              length: 0,
              createdAt: createdAt.toDate(),
              lastEdit: createdAt.toDate(),
              url,
              status: "UPLOADED",
              tenantId: REACT_APP_TENANT_ID,
              source: "saas",
              lang,
              fileExt: fileExtension || null,
            };

            if (lang === "vn" || lang === "jp") {
              if (numberOfSpeakers) payload.numberOfSpeakers = numberOfSpeakers;
              if (splitScd) payload.splitScd = splitScd;
            }

            await db.collection("files").doc(fileUUID).set(payload);
          });
          if (e.onSuccess) e.onSuccess();
          else setSubmit(false);
        }
      );
    }
  };

  const props = {
    name: "file",
    customRequest: onSelectFile,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
    multiple: true,
  };

  const submitLink = async (e) => {
    setSubmit(true);
    if (e.links) {
      if (e.links.some(({ link }) => !isValidUrl(link))) {
        message.error("Some links are invalid. Please check again!");
        setSubmit(false);
        return;
      }

      e.links.forEach(async (item, index) => {
        const url = item.link;
        const fileUUID = uuid.v4();

        let payload = {
          uuid: fileUUID,
          name: item.link,
          user: user?.uid,
          length: 0,
          createdAt: new Date(),
          lastEdit: new Date(),
          url,
          status: "DOWNLOADING",
          tenantId: REACT_APP_TENANT_ID,
          source: "saas",
          lang,
        };

        if (lang === "vn" || lang === "jp") {
          if (numberOfSpeakers) payload.numberOfSpeakers = numberOfSpeakers;
          if (splitScd) payload.splitScd = splitScd;
        }

        db.collection("files").add(payload);
      });
      message.success("We're downloading your files. Please wait a moment!");
    }
    setSubmit(false);
  };

  const handleExportCSV = async () => {
    setIsExporting(true);
    setExportType("csv");
    try {
      const dateFormat = "DD/MM/YYYY HH:mm";
      const data = filteredFiles.map((file) => ({
        name: file.name,
        url: file.url,
        length: file.length,
        createdAt: moment(file.createdAt).format(dateFormat),
        annotator: file.annotator || "",
        annotatorDeadline: file.annotatorDeadline
          ? moment(file.annotatorDeadline).format(dateFormat)
          : "",
        annotatorSubmission: file.annotatorSubmittedAt
          ? moment(file.annotatorSubmittedAt).format(dateFormat)
          : file.annotatorSubmitted
          ? "true"
          : "",
        reviewer: file.reviewer || "",
        reviewerDeadline: file.reviewerDeadline
          ? moment(file.reviewerDeadline).format(dateFormat)
          : "",
        reviewerSubmission: file.reviewerSubmittedAt
          ? moment(file.reviewerSubmittedAt).format(dateFormat)
          : file.reviewerSubmitted
          ? "true"
          : "",
        approver: file.approver || "",
        approverDeadline: file.approverDeadline
          ? moment(file.approverDeadline).format(dateFormat)
          : "",
        approverSubmission: file.approverSubmittedAt
          ? moment(file.approverSubmittedAt).format(dateFormat)
          : file.approverSubmitted
          ? "true"
          : "",
      }));

      csvDownload({
        data,
        filename: `file_export_${moment().format("YYYYMMDDHHmmss")}.csv`,
        delimiter: ",",
        headers: [
          "Name",
          "File URL",
          "Length",
          "Upload Date",
          "Annotator",
          "Annotator Deadline",
          "Annotator Submission",
          "Reviewer",
          "Reviewer Deadline",
          "Reviewer Submission",
          "Approver",
          "Approver Deadline",
          "Approver Submission",
        ],
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsExporting(false);
    }
  };

  const handleExportData = async () => {
    setIsExporting(true);
    setExportType("data");
    try {
      // For export json data
      const allExportData = [];

      for (const fileChunk of chunk(filteredFiles, 5)) {
        await Promise.all(
          fileChunk.map(async (file) => {
            const id = file.id;
            const fileRef = db.collection("files").doc(id);

            const rawSentencesDocs = await fileRef
              .collection("sentences")
              .get();

            // Get raw sentences data
            const rawSentences = rawSentencesDocs.docs.map((doc) => doc.data());

            // Add raw sentences to all Data
            allExportData.push({
              fileName: file.name + "_raw",
              jsonData: {
                email: "none",
                id: file.id,
                data: rawSentences,
              },
            });

            // Get edited sentences data
            const editedSentencesDocs = await fileRef
              .collection("edited")
              .get();

            editedSentencesDocs.forEach((doc) => {
              const sentences = doc.data().sentences;

              let email = "";
              let phase = doc.id;

              switch (doc.id) {
                case "annotator":
                  email = file.annotator;
                  break;
                case "reviewer":
                  email = file.reviewer;
                  break;
                case "user":
                  email = file.approver;
                  phase = "approver";
                  break;
                default:
              }

              allExportData.push({
                fileName: file.name + "_" + phase,
                jsonData: {
                  email,
                  id: file.id,
                  data: sentences,
                },
              });
            });
          })
        );
      }

      const zip = new JSZip();

      for (const exportData of allExportData) {
        const { fileName, jsonData } = exportData;

        const blob = new Blob([JSON.stringify(jsonData, null, 4)], {
          type: "application/json",
        });

        zip.file(fileName + ".json", blob);
      }
      zip.file(
        "audio_list.txt",
        filteredFiles
          .map(
            (file) => `${file.name}.${getUrlExtension(file.url)}\t${file.url}`
          )
          .join("\n")
      );

      const content = await zip.generateAsync({ type: "blob" });

      saveAs(content, "export.zip");
    } catch (e) {
      console.log(e);
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    if (user?.email?.endsWith("demo.jp")) {
      setTranscriptLanguague("jp");
    } else {
      setTranscriptLanguague("vn");
    }
  }, [user?.email]);

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
        {(!dataToolRole ||
          dataToolRole === "admin" ||
          dataToolRole === "tool") && (
          <Card style={{ marginBottom: 18 }}>
            <Row style={{ marginBottom: 8 }}>
              <Space>
                <Space>
                  Transcript language:
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setTranscriptLanguague("vn");
                            }}
                          >
                            Vietnamese
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setTranscriptLanguague("jp");
                            }}
                          >
                            Japanese
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setTranscriptLanguague("thai");
                            }}
                          >
                            Thai
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              setTranscriptLanguague("indo");
                            }}
                          >
                            Indonesian
                          </div>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Space size={4}>
                      <span style={{ color: "#4a90e2", fontWeight: "bold" }}>
                        {lang ? (
                          <>
                            {lang === "vn" && "Vietnamese"}
                            {lang === "jp" && "Japanese"}
                            {lang === "thai" && "Thai"}
                            {lang === "indo" && "Indonesian"}
                          </>
                        ) : (
                          "Select language"
                        )}
                      </span>
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </Space>
                {(lang === "vn" || lang === "jp") && (
                  <>
                    <Space>
                      <span>Maximum number of speakers (optional):</span>
                      <InputNumber
                        min={1}
                        placeholder="10"
                        value={numberOfSpeakers}
                        onChange={(e) => {
                          setNumberOfSpeakers(e);
                        }}
                      />
                    </Space>
                    <Checkbox
                      checked={splitScd}
                      onChange={(e) => setSplitScd(e.target.checked)}
                    >
                      Split SCD
                    </Checkbox>
                  </>
                )}
              </Space>
            </Row>
            <Row gutter={24} style={{ marginTop: 24 }}>
              <Col span={12}>
                <Card style={{ height: `100%` }}>
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <DownloadOutlined
                        style={{ fontSize: 36, color: "inherit" }}
                      />
                    </p>
                    <p className="ant-upload-text">
                      {language.selectDragDropVideo}
                    </p>
                  </Dragger>
                </Card>
              </Col>
              <Col span={12}>
                <Card style={{ height: `100%`, justifyContent: "center" }}>
                  <p style={{ fontSize: 16 }}>{language.pasteVideo}</p>
                  <Form
                    onFinish={submitLink}
                    initialValues={{
                      links: [
                        {
                          link: "http://link.com/video.mp4",
                        },
                      ],
                    }}
                  >
                    <Form.List name="links">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <>
                              <Row gutter={24}>
                                <Col lg={{ span: 20 }} xs={{ span: 20 }}>
                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                      {
                                        type: "url",
                                        message: "Please enter a valid url",
                                      },
                                    ]}
                                    label={"Link"}
                                    {...restField}
                                    name={[name, "link"]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col lg={{ span: 4 }} xs={{ span: 4 }}>
                                  {fields.length !== 1 && (
                                    <>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />{" "}
                                      {language.btnDelete}
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </>
                          ))}
                          <Button type="primary" htmlType="submit">
                            {language.btnSubmit}
                          </Button>
                          <Button
                            loading={submit}
                            type="link"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            {language.btnAdd}
                          </Button>
                        </>
                      )}
                    </Form.List>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Card>
        )}
        {(dataToolRole === "admin" || dataToolRole === "tool") && (
          <Card
            style={{
              marginBottom: 18,
            }}
          >
            <Space
              direction="horizontal"
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              <Space direction="horizontal">
                <Button
                  disabled={filteredFiles.length === 0 || isExporting}
                  loading={isExporting && exportType === "csv"}
                  type="primary"
                  onClick={handleExportCSV}
                >
                  Export CSV
                </Button>
                <Button
                  disabled={filteredFiles.length === 0 || isExporting}
                  loading={isExporting && exportType === "data"}
                  type="primary"
                  onClick={handleExportData}
                >
                  Export data
                </Button>
                <div
                  style={{
                    borderLeft: "2px solid #f0f0f0",
                    height: 24,
                  }}
                />
                <Upload
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={() => false}
                  accept=".csv"
                  onChange={async (e) => {
                    const file = e.file;
                    if (!file) return;
                    const isCsv = file.type === "text/csv";
                    if (!isCsv) {
                      message.error("Please select a CSV file");
                      return;
                    }
                    setIsBatchAssigning(true);

                    try {
                      const { data } = await new Promise((resolve, reject) => {
                        Papa.parse(file, {
                          complete: resolve,
                          error: reject,
                        });
                      });

                      const errorFiles = [];
                      for (let i = 1; i < data.length; i++) {
                        const row = data[i];
                        try {
                          if (row.length < 7) {
                            errorFiles.push({
                              name: row[0],
                              line: i + 1,
                              reason: "Missing columns",
                            });
                            continue;
                          }
                          const name = row[0];
                          const annotator = row[1]?.trim();
                          const annotatorDeadline = row[2]?.trim();
                          const reviewer = row[3]?.trim();
                          const reviewerDeadline = row[4]?.trim();
                          const approver = row[5]?.trim();
                          const approverDeadline = row[6]?.trim();

                          const file = await db
                            .collection("files")
                            .where("name", "==", name)
                            .where("user", "==", user?.uid)
                            .limit(1)
                            .get();

                          if (file.empty) {
                            errorFiles.push({
                              name,
                              line: i + 1,
                              reason: "File with this name not found",
                            });
                            continue;
                          } else {
                            const fileDoc = file.docs[0];
                            const updateData = {};
                            if (annotator)
                              updateData.annotator =
                                annotator === "null" ? null : annotator;
                            if (annotatorDeadline)
                              updateData.annotatorDeadline =
                                annotatorDeadline === "null"
                                  ? null
                                  : moment(
                                      annotatorDeadline,
                                      "DD/MM/YYYY HH:mm"
                                    ).valueOf();
                            if (reviewer)
                              updateData.reviewer =
                                reviewer === "null" ? null : reviewer;
                            if (reviewerDeadline)
                              updateData.reviewerDeadline =
                                reviewerDeadline === "null"
                                  ? null
                                  : moment(
                                      reviewerDeadline,
                                      "DD/MM/YYYY HH:mm"
                                    ).valueOf();
                            if (approver)
                              updateData.approver =
                                approver === "null" ? null : approver;
                            if (approverDeadline)
                              updateData.approverDeadline =
                                approverDeadline === "null"
                                  ? null
                                  : moment(
                                      approverDeadline,
                                      "DD/MM/YYYY HH:mm"
                                    ).valueOf();
                            await fileDoc.ref.update(updateData);
                          }
                        } catch (e) {
                          errorFiles.push({
                            name: row[0] || "",
                            line: i + 1,
                            reason: e.message || "Unknown error",
                          });
                        }
                      }
                      if (errorFiles.length > 0) {
                        message.error(
                          "Some file errors occurred while batch assigning"
                        );
                        csvDownload({
                          data: errorFiles,
                          filename: `error_file_${moment().format(
                            "YYYYMMDDHHmmss"
                          )}.csv`,
                          delimiter: ",",
                          headers: ["Name", "Line", "Reason"],
                        });
                      } else {
                        message.success("Batch assign success");
                      }
                    } catch (e) {
                      console.log(e);
                      message.error("Invalid CSV file");
                    }
                    setIsBatchAssigning(false);
                  }}
                >
                  <Button loading={isBatchAssigning} type="default">
                    Batch assign
                  </Button>
                </Upload>
              </Space>
              {selectedRows.length > 0 && (
                <Space direction="horizontal" size="large">
                  <span>Selected {selectedRows.length} item(s)</span>
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      confirm({
                        title: language.doYouWantToDeleteTheseItems,
                        icon: <ExclamationCircleOutlined />,
                        onOk: async () => {
                          if (user) {
                            setLoading(true);
                            try {
                              await Promise.all(
                                selectedRows.map(async (record) => {
                                  const batch = db.batch();

                                  if (record.url) {
                                    try {
                                      await storage
                                        .refFromURL(record.url)
                                        .delete();
                                    } catch {}
                                  }
                                  if (record.taskId) {
                                    batch.update(
                                      db
                                        .collection("audioTasks")
                                        .doc(record.taskId),
                                      {
                                        status: "DELETED",
                                      }
                                    );
                                  }

                                  const fileDoc = db
                                    .collection("files")
                                    .doc(record.id);

                                  const sentences = await fileDoc
                                    .collection("sentences")
                                    .get();

                                  sentences.forEach((doc) => {
                                    batch.delete(doc.ref);
                                  });

                                  const sentencesEdited = await fileDoc
                                    .collection("sentencesEdited")
                                    .get();

                                  sentencesEdited.forEach((doc) => {
                                    batch.delete(doc.ref);
                                  });

                                  const editedDocs = await fileDoc
                                    .collection("edited")
                                    .get();

                                  await Promise.all(
                                    editedDocs.docs.map(async (doc) => {
                                      const batch = db.batch();
                                      const versions = await doc.ref
                                        .collection("versions")
                                        .get();
                                      versions.forEach((version) => {
                                        batch.delete(version.ref);
                                      });
                                      batch.delete(doc.ref);
                                      await batch.commit();
                                    })
                                  );

                                  batch.delete(fileDoc);

                                  await batch.commit();
                                })
                              );
                              message.success(language.deleteSuccess);
                            } catch (e) {
                              console.log(e);
                              message.error(language.deleteFail);
                            }
                            setLoading(false);
                          }
                        },
                        onCancel() {
                          console.log("Cancel");
                        },
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Space>
              )}
            </Space>
          </Card>
        )}
        <Table
          rowKey={"id"}
          loading={loading}
          bordered
          columns={columns}
          dataSource={files}
          onChange={(pagination, filters, sorter, extra) => {
            setFilteredFiles(extra.currentDataSource);
          }}
          rowSelection={{
            type: "checkbox",
            fixed: true,
            columnWidth: 60,
            onChange: (_, selectedRows) => {
              setSelectedRows(selectedRows);
            },
          }}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
          }}
        />
      </Layout>
    </Layout>
  );
};

export default SaasTranscripts;
