import { useEffect, useState } from "react";
import React from "react";
import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlayCircleFilled,
  PauseCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Space,
  Table,
  Tabs,
  Upload,
} from "antd";

import moment from "moment-timezone";

import axios from "axios";
import Audio from "./Audio";

import useAuth from "hooks/useAuth";
import { db, storage } from "utils/firebase";

import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/lang";

import UserTranscriptsLayout from "../../../components/web/Layout/UserTranscripts";
import "./styles.less";
import firebase from "firebase";
import TextArea from "antd/lib/input/TextArea";
import useRecorder from "./useRecorder";

const { confirm } = Modal;

const { TabPane } = Tabs;
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const asrThaiUrl = "wss://demothai.kkode.com/ws/streamfile";
const asrIndoUrl = "wss://demoindo.kkode.com/ws/streamfile";

const Users = () => {
  const { t } = useTranslation();
  const language = getLanguages(t);

  const { user } = useAuth();
  const [lang, setLang] = useState("vi");

  //Audio file
  const [baseAudioUrl, setBaseAudioUrl] = useState("");
  const [audioName, setAudioName] = useState("");
  const [file, setFile] = useState();
  const [transcript, setTranscript] = useState("");
  const [transcriptEdit, setTranscriptEdit] = useState("");
  const [bugs, setBugs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState([moment().subtract(7, "days"), moment()]);
  const [fromDate, setFromDate] = useState(datePickerValue[0]);
  const [toDate, setToDate] = useState(datePickerValue[1]);

  const [audioURL, audioBlob, isRecording, startRecording, stopRecording] = useRecorder();
  const [startRec, setStartRec] = useState(false);
  const [msgRec, setMsgRec] = useState(language.secLimit);
  const [timeoutIdRec, setTimeoutIdRec] = useState(-1);
  const [timeIntervalIdRec, setTimeIntervalIdRec] = useState(-1);

  const [isTranscribing, setIsTranscribing] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (audioURL) {
      setBaseAudioUrl(audioURL);
      setAudioName("File ghi âm");
      setFile(audioBlob);
    }
  }, [audioURL]);

  useEffect(() => {
    if (user) {
      setLoading(true);
      const unsubscribe = db
        .collection("bugs")
        .orderBy("createdAt", "desc")
        .where("createdAt", ">", fromDate.startOf("day").toDate())
        .where("createdAt", "<", toDate.endOf("day").toDate())
        .where("user", "==", user.uid)
        .onSnapshot((snapshot) => {
          const bugs = snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
          setBugs(bugs);
          setLoading(false);
        });

      return () => unsubscribe();
    }
  }, [user, fromDate, toDate]);

  const columns = [
    {
      title: language.createdAt,
      align: "center",
      dataIndex: "createdAt",
      width: "10%",
      render: (record) => {
        return (
          <div>
            <div>{moment(record.toDate()).format("YYYY-MM-DD HH:mm")}</div>
          </div>
        );
      },
    },
    {
      title: language.transcript,
      dataIndex: "transcript",
      render: (transcript) => {
        return <div style={{ whiteSpace: "pre-line" }}>{transcript}</div>;
      },
    },
    {
      title: language.correctedTranscript,
      dataIndex: "transcriptEdit",
      render: (transcript) => {
        return <div style={{ whiteSpace: "pre-line" }}>{transcript ?? "-"}</div>;
      },
    },
    {
      title: language.audio,
      align: "center",
      width: "8%",
      render: (record) => {
        return <audio src={record?.fileUrl} controls="controls" />;
      },
    },
    {
      title: "Language",
      dataIndex: "lang",
      align: "center",
      width: "8%",
      render: (lang) => {
        return (
          <div>
            {lang === "vi" && "Vietnamese"}
            {lang === "jp" && "Japanese"}
            {lang === "thai" && "Thai"}
            {lang === "indo" && "Indonesian"}
          </div>
        );
      },
    },
    {
      title: language.actions,
      align: "center",
      width: "8%",
      render: (record) => {
        return (
          <Button
            icon={<DeleteOutlined />}
            type="link"
            danger
            onClickCapture={() => {
              confirm({
                title: language.doYouWantToDeleteTheseItems,
                icon: <ExclamationCircleOutlined />,
                onOk() {
                  db.doc(`/bugs/${record?.id}`)
                    .delete()
                    .then(async () => {
                      if (record.fileUrl) {
                        await storage.refFromURL(record.fileUrl).delete();
                        message.success(language.deleteSuccess);
                      }
                      setLoading(false);
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    });
                },
              });
            }}
          >
            {language.btnDelete}
          </Button>
        );
      },
    },
  ];

  const onSelectFile = (e) => {
    if (e.file.name) {
      const fileURL = URL.createObjectURL(e.file);
      setBaseAudioUrl(fileURL);
      setAudioName(e.file.name);
      setFile(e.file);
    }
  };

  const props = {
    name: "file",
    multiple: false,
    customRequest: onSelectFile,
    showUploadList: false,
  };

  useEffect(() => {
    if (isTranscribing) {
      setTranscript("");
      setTranscriptEdit("");
    }
    setEditing(false);
  }, [isTranscribing]);

  //Transcript
  useEffect(() => {
    if (lang === "thai" || lang === "indo") return;
    const sendData = async () => {
      if (baseAudioUrl?.length > 0) {
        setIsTranscribing(true);
        const blob = await fetch(baseAudioUrl).then((r) => r.blob());
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          axios
            .post(`${process.env.REACT_APP_MEETING_API_URL}/transcript`, {
              audio: base64data,
              lang,
              dict: [],
            })
            .then((res) => {
              setTranscript(res.data.transcript);
              setTranscriptEdit(res.data.transcript);
              setIsTranscribing(false);
            })
            .catch((e) => {
              setIsTranscribing(false);
            });
        };
      }
    };
    sendData();
  }, [baseAudioUrl]);

  //Transcript
  useEffect(() => {
    if (lang !== "thai" && lang !== "indo") return;
    let ws;
    const sendData = async () => {
      if (file) {
        setIsTranscribing(true);
        let reader = null;
        ws = new WebSocket(lang === "thai" ? asrThaiUrl : asrIndoUrl);
        let nextChunk;
        ws.addEventListener("open", async () => {
          reader = new FileReader();
          ws.send(file.name);
          ws.send(file.size);
          const chunkSize = 10000;
          let chunkOffset = 0;

          nextChunk = () => {
            if (reader.result) chunkOffset += reader.result.byteLength;
            if (chunkOffset < file.size) {
              reader.readAsArrayBuffer(file.slice(chunkOffset, chunkOffset + chunkSize));
            }
          };

          reader.onload = () => {
            ws.send(reader.result);
          };

          nextChunk();
          // ws.close();
        });

        ws.addEventListener("message", (event) => {
          const data = JSON.parse(event.data);
          if (data.cmd === "progress") {
            if (parseInt(data.progress) < 100) {
              nextChunk();
            }
          }
          if (data.cmd === "asrfull") {
            setTranscript(data.result);
            setTranscriptEdit(data.result);
            setIsTranscribing(false);
          }
          if (data.cmd === "asrpartial" && data.result) {
            setTranscript(`Transcribing: ` + data.result + "...");
          }
        });
      }
    };
    sendData();
    return () => {
      if (ws) ws.close();
    };
  }, [file]);

  const createBug = async () => {
    setLoading(true);
    try {
      const doc = db.collection("bugs").doc();
      const fileExt = audioName.split(".").pop();
      const fileName = `${doc.id}.${fileExt}`;
      const storageRef = storage.ref(`audio/${fileName}`);
      await storageRef.put(file);
      const fileUrl = await storageRef.getDownloadURL();
      await db.collection("bugs").add({
        transcript,
        transcriptEdit: editing ? transcriptEdit : null,
        createdAt: firebase.firestore.Timestamp.now(),
        user: user?.uid,
        fileUrl,
        lang,
      });

      setLoading(false);
      message.success(language.saveSuccess);
    } catch (e) {
      setLoading(false);
      console.log(e);
      message.error(language.saveFailed);
    }
  };

  const startRecord = () => {
    if (startRec) {
      clearTimeout(timeoutIdRec);
      clearInterval(timeIntervalIdRec);
      setStartRec(false);
      stopRecording();
    } else {
      setAudioName("");
      setFile(null);
      setTranscript("");
      setTranscriptEdit("");
      setBaseAudioUrl("");
      //log.debug("[App] start record " + isRecording);
      setStartRec(true);
      startRecording();
      let count = 600;
      setMsgRec(`${count} ${language.seconds} .`);
      const intevalRec = setInterval(() => {
        count -= 1;
        if (count % 3 === 0) {
          setMsgRec(`${count} ${language.seconds} .`);
        } else if (count % 3 === 2) {
          setMsgRec(`${count} ${language.seconds} . .`);
        } else {
          setMsgRec(`${count} ${language.seconds} . . .`);
        }
      }, 1000);
      setTimeIntervalIdRec(intevalRec);
      setTimeoutIdRec(
        setTimeout(() => {
          //log.debug("[App] auto stop");
          setStartRec(false);
          stopRecording();
          clearInterval(intevalRec);
        }, count * 1000)
      );
    }
  };

  return (
    <>
      <UserTranscriptsLayout />
      <Layout className="jobs-page">
        <Layout style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Card style={{ marginBottom: 18, marginTop: 20 }}>
            <Row>
              <RangePicker
                ranges={{
                  [language.today]: [moment(), moment()],
                  [language.thisMonth]: [moment().startOf("month"), moment().endOf("month")],
                }}
                value={datePickerValue}
                onChange={setDatePickerValue}
                al
              />
              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={() => {
                  if (datePickerValue[0] && datePickerValue[1]) {
                    setFromDate(datePickerValue[0]);
                    setToDate(datePickerValue[1]);
                  }
                }}
              >
                {language.search}
              </Button>
            </Row>
          </Card>

          <Card style={{ marginBottom: 18 }} bordered={false}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={12}>
                {language.selectLanguage}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24} style={{ position: "relative" }}>
                <Radio.Group onChange={(e) => setLang(e.target.value)} value={lang}>
                  <Space direction="vertical">
                    <Radio value={"vi"}>{language.vietnamese}</Radio>
                    <Radio value={"jp"}>{language.japanese}</Radio>
                    <Radio value={"thai"}>Thai</Radio>
                    <Radio value={"indo"}>Indonesian</Radio>
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 24 }}>
              <Col span={12}>
                <Dragger {...props} disabled={isRecording}>
                  <p className="ant-upload-drag-icon">
                    <DownloadOutlined style={{ fontSize: 36, color: "inherit" }} />
                  </p>
                  <p className="ant-upload-text">{language.selectDragDropAudio}</p>
                </Dragger>
              </Col>
              <Col span={12}>
                <Card style={{ height: 140 }}>
                  <p style={{ textAlign: "center" }}>
                    {startRec ? (
                      <a onClick={startRecord}>
                        <PauseCircleFilled style={{ fontSize: 24, color: "#BD271E" }} />
                        <span
                          style={{
                            fontSize: 24,
                            color: "#BD271E",
                            marginLeft: 10,
                          }}
                        >
                          {language.btnStop}
                        </span>
                      </a>
                    ) : (
                      <a onClick={startRecord}>
                        <PlayCircleFilled style={{ fontSize: 24, color: "#BD271E" }} />
                        <span
                          style={{
                            fontSize: 24,
                            color: "#BD271E",
                            marginLeft: 10,
                          }}
                        >
                          {language.btnRecord}
                        </span>
                      </a>
                    )}
                    <br />
                    {msgRec}
                  </p>
                </Card>
              </Col>
            </Row>
            {baseAudioUrl && (
              <Row style={{ marginTop: 10 }}>
                <Col span={24}>
                  <Audio audioName={audioName} playerID={"player"} audioUrl={baseAudioUrl} audioDuration={10} />
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>{isTranscribing && !transcript ? language.predicting : transcript}</Col>
            </Row>
            {transcript && !isTranscribing && (
              <>
                <Row style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Checkbox checked={editing} onChange={(e) => setEditing(e.target.checked)}>
                      {language.btnEdit}
                    </Checkbox>
                    <Button loading={loading} type="primary" onClick={createBug} disabled={isTranscribing}>
                      {language.btnSave}
                    </Button>
                  </Col>
                </Row>
                {editing && (
                  <TextArea
                    rows={4}
                    style={{ marginTop: 10 }}
                    value={transcriptEdit}
                    onChange={(e) => setTranscriptEdit(e.target.value)}
                  />
                )}
              </>
            )}
          </Card>

          <Tabs defaultActiveKey="1">
            <TabPane tab={`Transcribed audios (${bugs.length})`} key="1">
              <Table
                loading={loading}
                bordered
                columns={columns}
                dataSource={bugs}
                pagination={{
                  defaultPageSize: 20,
                }}
                footer={() => t("totalLines", { lines: bugs.length })}
              />
            </TabPane>
          </Tabs>
        </Layout>
      </Layout>
    </>
  );
};

export default Users;
